import React from "react"
// import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"

export default function Newsroom({ data }) {
  return (
    <Layout>
      <div className="p-4 sm:p-10 md:p-16 bg-flamingo-100 h-full">

        <Header headerText="Aktualności" />
          {/* <h4>{data.allMarkdownRemark.totalCount} postów</h4>
        {data.allMarkdownRemark.edges.map(({ node }) => (node.fields && 
          <div key={node.id}>
            <Link to={node.fields.slug}>
              <h3>
                {node.frontmatter.title}{" "}
                <span>— {node.frontmatter.date}</span>
              </h3>
              <p>{node.excerpt}</p>
            </Link>
          </div>
        ))}  */}
        </div>
    </Layout>
  )
}

// export const query = graphql`
//   query {
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       totalCount
//       edges {
//         node {
//           id
//           frontmatter {
//             title
//             date(formatString: "DD MMMM, YYYY")
//           }
//           fields {
//             slug
//           }
//           excerpt
//         }
//       }
//     }
//   }
// `